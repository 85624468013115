.fade-border {
    position: relative;
    overflow: hidden;
  }
  
  .fade-border img {
    height: 100%;
    display: block;
  }
  
  .fade-border::before {
    width: 100rem;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 1));
    pointer-events: none;
  }